import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useAppContext } from 'contexts/app-context';
import { getLocalizedField } from 'utils/get-localized-field';
import {
  GET_INITIAL_TAGGED_CONTENT,
  GET_FILTERED_TAGGED_CONTENT,
} from 'queries/tagged-content';
import { ArticleGrid, DynamicFilter } from 'components/organisms';
import { Button, Headline } from 'components/atoms';
import startCase from 'lodash/startCase';
import { LoadingIndicator } from '@spotify-internal/encore-web';
import flattenDeep from 'lodash/flattenDeep';
import PropTypes from 'prop-types';
import * as Styled from './TaggedContentList.styled';

/**
 * Component to display a filter bar and tagged content.
 * Dynamically fetches tagged content as the filter bar values change.
 */
export const TaggedContentList = ({ className = '', data }) => {
  const [{ locale }] = useAppContext();
  const groupId = data.id;
  const categoriesList = data.categoriesCollection.items;
  const { title } = data;
  // List of all subcategory native tags
  const [allNativeTagIds, setAllNativeTagIds] = useState([]);
  const [selectedFiltersState, setSelectedFiltersState] = useState({});
  const [pageContent, setPageContent] = useState([]);
  // List of filter categories and subcategories
  const [filterCollection, setFilterCollection] = useState([]);
  const [totalContentCount, setTotalContentCount] = useState(0);
  const contentCountPerPage = 9;
  const [filterOpen, setFilterOpen] = useState(false);
  /**
   * Reformat data into FilterCategoriesType format and native-tag IDs.
   */
  useEffect(() => {
    const nativeTags = [];
    const filtersList = [];
    for (let i = 0; i < categoriesList.length; i += 1) {
      const category = categoriesList[i];
      const subCategoriesCollection = [];

      for (
        let j = 0;
        j < category.subcategoriesCollection.items.length;
        j += 1
      ) {
        const subCategory = category.subcategoriesCollection.items[j];
        // Combine cat. id and sub-cat. id and camelCase them to get the native tag ID.
        const nativeTagId = `${category.id}${subCategory.id
          .charAt(0)
          .toUpperCase()}${subCategory.id.slice(1)}`;
        nativeTags.push(nativeTagId);
        subCategoriesCollection.push({
          name: subCategory.name,
          id: nativeTagId,
        });
      }
      filtersList.push({
        name: category.name,
        id: category.id,
        subCategories: subCategoriesCollection,
        isAllOptionEnabled: category.allOptionEnabled,
      });
    }
    setAllNativeTagIds(nativeTags);
    setFilterCollection(filtersList);
  }, [data]);

  const contentQueryLocale = getLocalizedField(
    startCase(locale.toLowerCase()).replace(' ', ''),
  );
  const pageDetailCollectionName = `pageDetailCollection`;
  const pageDetailGlobalCollectionName = `pageDetailGlobalCollection`;

  /**
   * Maps the selected filter state to variables expected by the filtered-content GQL query.
   */
  const mapFilterStateToGqlQueryVariables = filterState => {
    const categories = {};
    const categoriesArray = Object.values(filterState).map(subCats =>
      subCats.map(filt => filt.id),
    );

    for (let i = 0; i < 5; i += 1) {
      if (categoriesArray[i] && categoriesArray[i].length) {
        // @ts-ignore
        categories[`category${i}`] = categoriesArray[i];
      } else {
        // @ts-ignore
        categories[`category${i}`] = null;
      }
    }
    return categories;
  };

  const isAnyTagsSelected = filterState =>
    !!flattenDeep(Object.values(filterState)).length;

  /**
   * Load initial/default content.
   */
  const {
    loading: initialContentLoading,
    error: initialContentError,
    data: initialContent,
    refetch: refetchInitialContent,
  } = useQuery(
    GET_INITIAL_TAGGED_CONTENT({
      locale: contentQueryLocale,
    }),
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        nativeTagIds: allNativeTagIds,
        groupId: [groupId],
        skip: 0,
        limit: contentCountPerPage,
      },
    },
  );

  useEffect(() => {
    if (initialContent) {
      const pageDetailCollection = initialContent?.[pageDetailCollectionName];
      const pageDetailGlobalCollection =
        initialContent?.[pageDetailGlobalCollectionName];
      const sum = pageDetailCollection.total + pageDetailGlobalCollection.total;

      if (pageDetailCollection && pageDetailGlobalCollection) {
        setTotalContentCount(sum);
        setPageContent(
          pageDetailCollection.items.concat(pageDetailGlobalCollection.items),
        );
      }
    }
  }, [
    initialContent,
    pageDetailCollectionName,
    pageDetailGlobalCollectionName,
  ]);

  /**
   * Async-load filtered content.
   */
  const [
    getFilteredTaggedContent,
    {
      loading: filteredContentLoading,
      error: filteredContentError,
      data: filteredContent,
    },
  ] = useLazyQuery(
    GET_FILTERED_TAGGED_CONTENT({
      locale: contentQueryLocale,
    }),
    {
      variables: {
        ...mapFilterStateToGqlQueryVariables(selectedFiltersState),
        groupId: [groupId],
        skip: 0,
        limit: contentCountPerPage,
      },
    },
  );

  useEffect(() => {
    if (filteredContent) {
      const pageDetailCollection = filteredContent?.[pageDetailCollectionName];
      const pageDetailGlobalCollection =
        filteredContent?.[pageDetailGlobalCollectionName];
      const sum = pageDetailCollection.total + pageDetailGlobalCollection.total;

      if (pageDetailCollection && pageDetailGlobalCollection) {
        setTotalContentCount(sum);
        setPageContent(
          pageDetailGlobalCollection.items.concat(pageDetailCollection.items),
        );
      } else {
        setTotalContentCount(0);
      }
    }
  }, [filteredContent]);

  const onFilterBarChange = newSelectedFiltersState => {
    setSelectedFiltersState(newSelectedFiltersState);
    if (isAnyTagsSelected(newSelectedFiltersState)) {
      getFilteredTaggedContent({
        variables: {
          ...mapFilterStateToGqlQueryVariables(newSelectedFiltersState),
          skip: 0,
        },
      });
    } else {
      refetchInitialContent({
        skip: 0,
      });
    }
  };

  const handleFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const closeOverlay = () => setFilterOpen(false);

  return (
    <Styled.Container className={className}>
      <Styled.HeadingContainer filterOpen={filterOpen}>
        <Headline tag="h2" styling="h1" text={title} />
        {!filterOpen ? (
          <Button type="filter" text="Filter" onClick={handleFilterClick} />
        ) : (
          <Button type="filterName" text="Filter" onClick={handleFilterClick} />
        )}
      </Styled.HeadingContainer>
      {filterOpen && (
        <DynamicFilter
          onFilterBarChange={onFilterBarChange}
          filterName={data.name}
          categories={filterCollection}
          totalContentCount={totalContentCount}
          filterState={selectedFiltersState}
          filterSectionOpen={filterOpen}
          closeOverlay={closeOverlay}
        />
      )}
      <Styled.ResultsContainer filterOpen={filterOpen}>
        {/* Error states */}
        {initialContentError && initialContentError}
        {filteredContentError && filteredContentError}
        {/* Loading state */}
        {(initialContentLoading || filteredContentLoading) && (
          <Styled.LoadingIndicatorContainerStyled>
            <LoadingIndicator indicatorSize={LoadingIndicator.lg} />
          </Styled.LoadingIndicatorContainerStyled>
        )}
        {/* No results state */}
        {!(initialContentLoading || filteredContentLoading) &&
          (pageContent.length === 0 &&
          isAnyTagsSelected(selectedFiltersState) ? (
            <Styled.EmptyContainer>
              <Styled.NoResultsText>{data?.noResultsText}</Styled.NoResultsText>
              <Styled.NoResultsSubText>
                {data?.noResultsSubText}
              </Styled.NoResultsSubText>
            </Styled.EmptyContainer>
          ) : (
            <ArticleGrid
              articles={pageContent}
              title={initialContent?.title}
              contentCountPerPage={contentCountPerPage}
            />
          ))}
      </Styled.ResultsContainer>
    </Styled.Container>
  );
};

TaggedContentList.propTypes = {
  /**
   * list of categories
   */
  className: PropTypes.string,
  /**
   * function to use for when dropdown value changes
   */
  data: PropTypes.object,
};
