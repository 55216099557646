import React from 'react';
import PropTypes from 'prop-types';

import { Video, VIDEO_TYPES } from 'components/atoms';
import { VideoPlayer } from '@spotify-internal/encore-web/ads-business/components/VideoPlayer/V2';
import { ASPECT_RATIOS } from '@spotify-internal/encore-web/ads-business/utils/config';
import * as Styled from './VideoSection.styled';

const VideoSection = ({
  type,
  videoId,
  videoName,
  caption,
  verticalVideo,
  pageContext = '',
}) => {
  return (
    <Styled.Root
      className={pageContext}
      hasVerticalVideo={verticalVideo && verticalVideo.url}
    >
      {verticalVideo && (
        <VideoPlayer
          videoProps={{
            src: verticalVideo.url,
          }}
          aspectRatio={ASPECT_RATIOS.PORTRAIT}
          width="50%"
          height="50%"
        />
      )}
      {videoId && videoName && type && (
        <Video
          type={type}
          videoId={videoId}
          videoName={videoName}
          caption={caption}
          verticalVideo={verticalVideo}
        />
      )}
    </Styled.Root>
  );
};

VideoSection.propTypes = {
  /**
   * Video Type
   */
  type: PropTypes.oneOf([VIDEO_TYPES.YOUTUBE, VIDEO_TYPES.VIMEO]),
  /**
   * Video Id
   */
  videoId: PropTypes.string,
  /**
   * Video Name
   */
  videoName: PropTypes.string,
  /**
   * An optional caption at the bottom of the video
   */
  caption: PropTypes.string,
  /**
   * Page type
   */
  pageContext: PropTypes.string,
};

export default VideoSection;
