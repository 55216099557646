import React, { useState } from 'react';
import { kebabCase } from 'lodash';
import ContentTile from 'components/molecules/content-tile/ContentTile';
import { Button } from 'components/atoms';
import PropTypes, { any } from 'prop-types';
import { useImageSupport } from 'utils/use-image-support';
import { CONTENT_TILE_THEMES } from 'utils/get-theme-selector';
import { eventTrack, CURATED_LIST_CTA_CLICK } from 'utils/google-tag-manager';
import { getPageEyebrow } from 'utils/get-page-eyebrow';
import {
  black,
  blue,
  yellowGreen,
  blueViolet,
  brownYellow,
  red,
} from 'styles/colors';
import { useAppContext } from 'contexts/app-context';
import { getDefaultBackground } from '../curated-list/CuratedList';
import * as Styled from './ArticleGrid.styled';

/**
 * Renders the Article Grid.
 * @param {Array} articles
 * @param {number} contentCountPerPage
 * @returns {ReactElement}
 */
const ArticleGrid = ({ articles = [], contentCountPerPage = 9 }) => {
  const { queryUrl, fileExt } = useImageSupport();
  const [{ locale }] = useAppContext();
  const { defaultUrl, defaultDescription } = getDefaultBackground(fileExt);
  const [numPages, setNumPages] = useState(1);

  const getColor = index => {
    const colors = [
      blueViolet,
      red,
      brownYellow,
      yellowGreen,
      blue,
      yellowGreen,
      brownYellow,
      red,
      blueViolet,
    ];
    return colors[index];
  };

  const renderTile = (article, index, cardStyle) => {
    const {
      navigationTag: { slug: urlTag },
      hero,
      slug,
      meta,
    } = article;
    const tileEyebrow = getPageEyebrow(article);
    const tileTitle = meta?.metaTitle || hero?.title || '';
    const tile = {
      title: tileTitle,
      backgroundColor: getColor(index),
      textColor: black,
      eyebrow: tileEyebrow,
      themeSelector: CONTENT_TILE_THEMES.CONTENT,
    };
    if (cardStyle === 'small' || cardStyle === 'medium') {
      tile.image = null;
    } else if (meta?.image) {
      tile.image = {
        description: meta?.image.description,
        url: meta?.image[queryUrl],
      };
    } else {
      tile.image = {
        defaultDescription,
        url: defaultUrl,
      };
    }

    const onCardClick = event => {
      eventTrack(CURATED_LIST_CTA_CLICK, {
        event,
        title: tileTitle,
        eyebrow: tileEyebrow,
        length: articles.length,
      });
    };
    return (
      <a
        href={`/${locale}/${urlTag}/${slug}`}
        key={kebabCase(`${tileEyebrow} ${index}`)}
        aria-label={`content tile link ${tileEyebrow}`}
      >
        <ContentTile
          tile={tile}
          handleClick={onCardClick}
          className={cardStyle}
        />
      </a>
    );
  };

  const renderGridPage = page => {
    // create cards in 3 columns for the offset grid
    const articlesToShow = articles.slice(
      page * contentCountPerPage,
      (page + 1) * contentCountPerPage,
    );
    const col1 = articlesToShow.filter((_, index) => index % 3 === 0);
    const col2 = articlesToShow.filter((_, index) => index % 3 === 1);
    const col3 = articlesToShow.filter((_, index) => index % 3 === 2);
    return (
      <Styled.GridPage key={page}>
        <Styled.GridCol key={kebabCase(`${page}-1`)} colNumber="1">
          {col1.map((article, index) =>
            renderTile(article, index * 3, index % 2 === 0 ? 'long' : 'small'),
          )}
        </Styled.GridCol>
        <Styled.GridCol key={kebabCase(`${page}-2`)} colNumber="2">
          {col2.map((article, index) =>
            renderTile(
              article,
              index * 3 + 1,
              index % 2 === 0 ? 'medium' : 'longest',
            ),
          )}
        </Styled.GridCol>
        <Styled.GridCol key={kebabCase(`${page}-3`)} colNumber="3">
          {col3.map((article, index) =>
            renderTile(
              article,
              index * 3 + 2,
              index % 2 === 0 ? 'long' : 'small',
            ),
          )}
        </Styled.GridCol>
      </Styled.GridPage>
    );
  };

  return (
    <Styled.Container>
      <Styled.ArticleGrid>
        {[...Array(numPages).keys()].map(page => renderGridPage(page))}
      </Styled.ArticleGrid>
      <Styled.CtaContainer>
        {numPages * contentCountPerPage < articles.length ? (
          <Button
            type="secondary"
            text="View More"
            onClick={() => setNumPages(numPages + 1)}
          />
        ) : (
          <Button
            type="secondary"
            text="View Less"
            onClick={() => setNumPages(numPages - 1)}
          />
        )}
      </Styled.CtaContainer>
    </Styled.Container>
  );
};

ArticleGrid.propTypes = {
  /**
   * List of articles
   */
  articles: PropTypes.arrayOf(any),
  /**
   * number of tiles per page
   */
  contentCountPerPage: PropTypes.number,
};

export default ArticleGrid;
