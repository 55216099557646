import styled from 'styled-components';

import { Headline as HeadlineComponent } from 'components/atoms';
import Stat from 'components/molecules/stat/Stat';
import { container, columnsGutter } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { StatProps } from './Stats';

export const Root = styled.div<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor || 'transparent'};
  margin: 3.2rem auto;

  ${minWidth.lg`
    margin: 6.4rem auto;
  `}

  ${props =>
    props.backgroundColor
      ? `
      margin: 0 auto;`
      : ''}

  ${minWidth.lg`
    ${(props: any) =>
      props.backgroundColor
        ? `
margin: 0 auto;
padding-top: 6rem; padding-bottom: 6rem;`
        : ''}
  `}
`;

export const Stats = styled.div`
  ${container}
  ${columnsGutter}

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 3.2rem;
`;

const getStatGridColumn = (numStats: number) => {
  switch (numStats) {
    case 1:
      return 'span 8';
    case 2:
      return 'span 4';
    default:
      return 'span 3';
  }
};

export const StatList = styled.ul<{ numStats: number }>`
  ${columnsGutter}

  display: grid;
  grid-column: span 12;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 3.2rem;

  ${minWidth.md`
    grid-column: 1 / 13;
  `}

  ${minWidth.lg`
    grid-column: 3/ 12;
    grid-template-columns: ${(props: any) =>
      props.numStats < 3 ? 'repeat(8, 1fr)' : 'repeat(9, 1fr)'};
  `}
`;

export const StatListItem = styled(Stat)<{
  numStats: number;
  tag: string;
  modifier: string;
  stat: StatProps;
  bodyColor: string;
}>`
  grid-column: span 12;
  ${minWidth.md`
    grid-column: span ${(props: any) => (props.numStats < 3 ? 12 : 4)};
  `}
  ${minWidth.lg`
    grid-column: ${(props: any) => getStatGridColumn(props.numStats)}
  `}
`;

export const Headline = styled(HeadlineComponent).attrs({
  /* @ts-ignore */
  tag: 'h3',
  styling: 'h4',
})<{ color: string; numStats: number; children: React.ReactNode }>`
  grid-column: 1 / 13;
  margin-bottom: 3.2rem;
  margin-top: 3.2rem;

  ${props => (props.color ? `color: ${props.color};` : '')}
  ${minWidth.lg`
    grid-column: 3 / 12;
    margin-bottom: ${(props: any) => props.numStats < 3 && '6.4rem'};
  `}
`;
