import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation, i18n } from 'i18n/nexti18n';
import ContactForm from 'components/molecules/contact-form/ContactForm';
import { eventTrack, FOOTER_ICONS_CLICK } from 'utils/google-tag-manager';
import { ICONS } from 'constants/icons';
import { Headline } from 'components/atoms';
import * as Styled from './FooterForm.styled';

/**
 * This constant is used to track analytics events in contact form
 */
const FORM_NAME = 'get in touch - footer';

/**
 * Renders the footer contact form.
 * @returns {ReactElement}
 */
const FooterForm = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(t('lookingForSomething'));
  const followUs = t('Follow Us');
  const onSubmit = () => setTitle(t('wereOnIt'));

  useEffect(() => {
    setTitle(t('lookingForSomething'));
  }, [i18n.language]);

  const onSocialClick = useCallback(event => {
    eventTrack(FOOTER_ICONS_CLICK, { event });
  }, []);

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.ContentBackground>
          <Styled.TitleContainer>
            <Headline tag="h2" styling="h2" text={`__${title}__`} />
          </Styled.TitleContainer>
          <Styled.FormContainer>
            <ContactForm
              formName={FORM_NAME}
              onSubmitCallback={onSubmit}
              isFooterForm
            />
          </Styled.FormContainer>
          <Styled.FollowUsContainer>
            <Styled.SocialLink
              icons={Array.of({
                name: ICONS.INSTAGRAM,
                ariaLabel: 'shareOnInstagram',
                link: 'https://www.instagram.com/spotifyads/',
                target: 'https://www.instagram.com/spotifyads/',
              })}
              onClick={onSocialClick}
            />
            <a
              href="https://www.instagram.com/spotifyads/"
              onClick={onSocialClick}
              aria-label="instagram link"
            >
              <Styled.FollowUsTitle
                tag="h5"
                styling="h5"
                text={`__${followUs}__`}
              />
            </a>
          </Styled.FollowUsContainer>
        </Styled.ContentBackground>
      </Styled.Content>
    </Styled.Container>
  );
};

export default FooterForm;
