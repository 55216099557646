/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes, { any } from 'prop-types';

import get from 'lodash/get';

import getConfig from 'next/config';
import { MODULES } from 'constants/modules';

import { SpotifyPlayer, ErrorBoundary, SectionDivider } from 'components/atoms';
import {
  Figure,
  VideoSection,
  Quote,
  RTE,
  AudioPlayer,
  Markdown,
  TopicCarousel,
  JumpLinkGroup,
  DynamicTextIntro,
  InlineCard,
  AdExperiences,
  VideoFeatureCard,
  Footnotes,
  CtaBanner,
  FeatureCard,
} from 'components/molecules';
import Accordion from 'components/molecules/accordion/Accordion';
import ChromeFeatureCard from 'components/molecules/chrome-feature-card/ChromeFeatureCard';
import {
  Catalog,
  Stats,
  Statement,
  InlineForm,
  CuratedListGroup,
  Topic,
  DynamicSection,
  TaggedContentList,
  PageCuratedList,
  TabbedCuratedList,
  TrendGrid,
  SliderTab,
  SplitView,
  StatSlideshow,
  TileCarousel,
  List,
  Gallery,
  FeatureCardContainer,
} from 'components/organisms';
import { gray95 } from '@spotify-internal/encore-web';

const { publicRuntimeConfig } = getConfig();
const ENVIRONMENT = publicRuntimeConfig.CONTENTFUL_ENVIRONMENT;

/**
 * Renders a a debug message if try to render unknown component, just for development
 * @param {Object} data The component object data.
 * @param {string} typename Content type ID from contentful
 * @returns {ReactElement}
 */
const DefaultModule = ({ data = {}, typename = '' }) =>
  ENVIRONMENT !== 'development' ? null : (
    <div
      style={{
        padding: '2rem',
        border: '0.1rem solid black',
        overflow: 'auto',
      }}
    >
      {typename ? (
        <>
          <h3 style={{ fontWeight: 'bold' }}>
            Component Missing:
            {typename}
          </h3>
          <code>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </code>
        </>
      ) : (
        <>
          <h2
            style={{
              fontWeight: 'bold',
              fontSize: '1.6em',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Component Missing
          </h2>
        </>
      )}
    </div>
  );

/**
 * Renders a specified module from the GraphQl endpoint data.
 * @param {Object} data The component object data.
 * @param {string} pageContext Verified page context (eg. PageDetail).
 * @returns {ReactElement}
 */
const ModuleMatrix = ({ data = {}, pageContext }) => {
  const { __typename: typename } = data;

  switch (typename) {
    case MODULES.RICH_TEXT:
      return <RTE body={data.body} modifier="grid" />;
    case MODULES.IMAGE:
      return <Figure data={data} type="image" pageContext={pageContext} />;
    case MODULES.AUDIO:
      return (
        <AudioPlayer
          title={data.title}
          caption={data.caption}
          data={data.audio}
          pageContext={pageContext}
          titleColor={data.titleColor}
        />
      );
    case MODULES.VIDEO:
      return (
        <VideoSection
          type={data.type}
          videoName={data.name}
          videoId={data.videoId}
          caption={data.caption}
          verticalVideo={data.verticalVideo}
          heroVideo={data.heroVideo}
          pageContext={pageContext}
        />
      );
    case MODULES.QUOTE:
      return (
        <Quote
          quote={data.quote}
          speaker={data.speaker}
          companyOrRole={data.companyOrRole}
          backgroundColor={data.backgroundColor}
          quoteColor={data.quoteColor}
          attributionTextColor={data.attributionTextColor}
          cta={data.cta}
          displayOnMobile={data.displayOnMobile}
          displaySplitView={data.displaySplitView}
          centered={data.centered}
          isArticleMargin={data.isArticleMargin}
        />
      );
    case MODULES.STATS:
      return (
        <Stats
          stats={data.statsCollection.items}
          title={data.title}
          backgroundColor={data.backgroundColor}
          theme={data.themeValue}
        />
      );
    case MODULES.STATEMENT:
      return (
        <Statement
          title={data.title}
          description={data.description}
          backgroundColor={data.backgroundColor}
          introColor={data.introColor}
          statementColor={data.statementColor}
          statement={data.statement}
          curatedLists={data.curatedListCollection.items}
          keyPoints={data.keyPoints}
        />
      );
    case MODULES.CATALOG:
      return (
        <Catalog
          title={data.title}
          entries={data.catalogEntriesCollection.items}
        />
      );
    case MODULES.CURATED_LIST_GROUP:
      return (
        <CuratedListGroup items={get(data, 'listsCollection.items', [])} />
      );
    case MODULES.CURATED_LIST:
      return (
        <PageCuratedList
          data={data?.pagesCollection?.items}
          title={data?.title}
          backgroundColor={gray95}
        />
      );
    case MODULES.SPOTIFY_PLAYER:
      return <SpotifyPlayer uri={data.uri} pageContext={pageContext} />;
    case MODULES.MARKDOWN_TEXT:
      return (
        <Markdown
          body={data.markdownBody}
          onSplitView={data.onSplitView}
          modifier="grid"
        />
      );
    case MODULES.FEATURE_CARD_CONTAINER:
      return (
        <FeatureCardContainer
          featureCards={get(data, 'featureCardsCollection.items', [])}
          themeSelector={data.themeSelector}
        />
      );
    case MODULES.FEATURE_CARD:
      return (
        <FeatureCard
          id={data.id}
          backgroundColor={data.backgroundColor}
          description={data.description}
          eyebrow={data.eyebrow}
          image={data.image}
          imagePosition={data.imagePosition}
          theme={data.theme}
          themeSelector={data.themeSelector}
          title={data.title}
          titleColor={data.titleColor}
          ctasCollection={data.ctasCollection}
          columnList={data.columnList}
        />
      );
    case MODULES.CHROME_FEATURE_CARD:
      return (
        <ChromeFeatureCard
          backgroundColor={data.backgroundColor}
          description={data.description}
          eyebrow={data.eyebrow}
          image={data.image}
          imagePosition={data.imagePosition}
          theme={data.theme}
          title={data.title}
          titleColor={data.titleColor}
          ctasCollection={data.ctasCollection}
          columnList={data.columnList}
          imageType={data.imageType}
        />
      );
    case MODULES.VIDEO_FEATURE_CARD:
      return (
        <VideoFeatureCard
          backgroundColor={data.backgroundColor}
          description={data.description}
          eyebrow={data.eyebrow}
          video={data.moduleVideo}
          videoPosition={data.videoPosition}
          theme={data.theme}
          title={data.title}
          titleColor={data.titleColor}
          ctasCollection={data.ctasCollection}
          columnList={data.columnList}
        />
      );
    case MODULES.TOPIC_CAROUSEL:
      return (
        <TopicCarousel
          eyebrow={data.eyebrow}
          entries={data.topicEntriesCollection}
        />
      );
    case MODULES.TOPIC:
      return (
        <Topic
          backgroundColor={data.backgroundColor}
          headlineColor={data.headlineColor}
          theme={data.themeValue}
          title={data.title}
          audioWithTranscript={data.audioWithTranscript}
        />
      );
    case MODULES.INLINE_FORM:
      return (
        <InlineForm
          name={data.name}
          title={data.title}
          action={data.action}
          ctaText={data.ctaText}
          disclaimer={data.disclaimer}
          failureMessage={data.failureMessage}
          successMessage={data.successMessage}
          fields={get(data, 'fieldsCollection.items', [])}
          formBackgroundColor={data.formBackgroundColor}
          fullWidthBackgroundColor={data.fullWidthBackgroundColor}
          formTextColor={data.formTextColor}
          placeholderTextColor={data.placeholderTextColor}
          isDark={data.isDark}
        />
      );
    case MODULES.JUMP_LINK_GROUP:
      return <JumpLinkGroup links={get(data, 'linksCollection.items', [])} />;
    case MODULES.DYNAMIC_TEXT_INTRO:
      return (
        <DynamicTextIntro
          heading={data.heading}
          subHeading={data.subHeading}
          jumpText={data.jumpText}
        />
      );
    case MODULES.DYNAMIC_SECTION:
      return (
        <DynamicSection
          title={data.title}
          titleDetailText={data.titleDetailText}
          backgroundColor={data.backgroundColor}
          timeRange={data.timeRange}
        />
      );
    case MODULES.ACCORDION:
      return (
        <Accordion
          entries={data.listsCollection.items}
          isDark={data.isDark}
          themeSelector={data.themeSelector}
        />
      );
    case MODULES.INLINE_CARD:
      return (
        <InlineCard
          image={data.image}
          fullWidthImage={data.fullWidthImage}
          eyebrow={data.eyebrow}
          title={data.title}
          subtitle={data.subtitle}
          textColor={data.textColor}
          backgroundColor={data.backgroundColor}
          description={data.description}
          ctasCollection={data.ctasCollection}
          isArticleMargin={data.isArticleMargin}
          themeSelector={data.themeSelector}
        />
      );
    case MODULES.AD_EXPERIENCES:
      return (
        <AdExperiences
          introTitle={data.introTitle}
          introImage={data.introImage}
          eyebrow={data.eyebrow}
          title1={data.title1}
          description1={data.description1}
          title2={data.title2}
          description2={data.description2}
          title3={data.title3}
          description3={data.description3}
          ctaHelpText={data.ctaHelpText}
          cta={data.cta}
        />
      );
    case MODULES.TREND_GRID:
      return (
        <TrendGrid
          trends={get(data, 'trendCardsCollection.items', [])}
          cta={data.cta}
          scrollVertical={data.scrollVertical}
          title={data.title}
          description={data.description}
        />
      );
    case MODULES.SLIDER_TAB:
      return (
        <SliderTab
          title={data.title}
          eyebrow={data.eyebrow}
          description={data.description}
          cta={data.cta}
          imagePosition={data.imagePosition}
          cards={get(data, 'cardsCollection.items', [])}
        />
      );
    case MODULES.STAT_SLIDESHOW:
      return (
        <StatSlideshow statCards={get(data, 'statCardsCollection.items', [])} />
      );
    case MODULES.TAGGED_CONTENT_GROUP:
      return <TaggedContentList data={data} />;
    case MODULES.TABBED_CURATED_LIST:
      return <TabbedCuratedList data={data} />;
    case MODULES.SPLIT_VIEW:
      return (
        <SplitView
          title={data.title}
          eyebrow={data.eyebrow}
          description={data.description}
          cta={data.cta}
          backgroundColor={data.backgroundColor}
          fullWidth={data.fullWidth}
          leftContent={get(data, 'leftContentCollection.items', [])}
          rightContent={get(data, 'rightContentCollection.items', [])}
        />
      );
    case MODULES.TILE_CAROUSEL:
      return (
        <TileCarousel
          title={data.title}
          description={data.description}
          cta={data.cta}
          tiles={get(data, 'contentTilesCollection.items', [])}
          scrollHorizontal={data.scrollHorizontal}
          scrollHorizontalMobile={data.scrollHorizontalMobile}
          fullWidth={data.fullWidth}
          isArticleMargin={data.isArticleMargin}
        />
      );
    case MODULES.LIST:
      return (
        <List
          listItems={get(data, 'listItemsCollection.items', [])}
          isSplitView={data.isSplitView}
        />
      );
    case MODULES.FOOTNOTES:
      return <Footnotes footnotes={data.footnotes} dropdown={data.dropdown} />;
    case MODULES.GALLERY:
      return (
        <Gallery
          columns={data.columns}
          rows={data.rows}
          items={get(data, 'itemsCollection.items', [])}
        />
      );
    case MODULES.SECTION_DIVIDER:
      return <SectionDivider />;
    case MODULES.CTA_BANNER:
      return (
        <CtaBanner
          title={data.title}
          description={data.description}
          backgroundColor={data.backgroundColor}
          textColor={data.textColor}
          centered
          isArticleMargin={data.isArticleMargin}
          ctas={data.ctasCollection}
        />
      );
    default:
      return <DefaultModule data={data} typename={typename} />;
  }
};

ModuleMatrix.propTypes = {
  data: PropTypes.objectOf(any).isRequired,
  pageContext: PropTypes.string,
};

/**
 * Renders component objects returned from the GraphQl endpoint via the
 * `ModuleMatrix` utility.
 * @param {Array} components An array of components, returned from the
 *    GraphQl query.
 */
const ModuleRenderer = ({ components = [], ...rest }) =>
  components.map((component, idx) => {
    const contentType = get(component, '__typename', 'no-type');
    const sysId = get(component, 'sys.id') || 'no-id-';

    return (
      <ErrorBoundary
        key={`${contentType}-${sysId}-${idx.toString()}`}
        sysId={sysId}
        contentType={contentType}
      >
        {component && <ModuleMatrix data={component} {...rest} />}
      </ErrorBoundary>
    );
  });

ModuleRenderer.propTypes = {
  components: PropTypes.arrayOf(any).isRequired,
};

export default ModuleRenderer;
