import React, { useMemo } from 'react';
import lowerCase from 'lodash/lowerCase';
import kebabCase from 'lodash/kebabCase';
import { solidBlack0, solidWhite200 } from '@spotify-internal/encore-web';

import * as Styled from './Stats.styled';

const THEME: { [theme: string]: string } = {
  dark: solidWhite200,
  light: solidBlack0,
};

/**
 * Renders a list of Stat objects.
 * @param {Array} stats - An array of `Stat` objects.
 * @param {string} title - The module title
 * @param {string} backgroundColor - The module background color
 * @param {string} theme - The module theme
 * @returns {ReactElement}
 */
const Stats = ({
  stats = [],
  title,
  backgroundColor,
  theme = '',
}: StatsProps) => {
  const numStats = stats.length;
  const textColor = useMemo(() => THEME[lowerCase(theme)], [theme]);

  return (
    <Styled.Root backgroundColor={backgroundColor}>
      <Styled.Stats>
        <Styled.Headline color={textColor} numStats={numStats}>
          {title}
        </Styled.Headline>
        <Styled.StatList numStats={numStats}>
          {stats.map((item, idx) => (
            <Styled.StatListItem
              tag="li"
              modifier="grid"
              key={kebabCase(`${item.stat} ${idx.toString()}`)}
              stat={item}
              numStats={numStats}
              bodyColor={textColor}
              /* @ts-ignore */
              theme={theme}
            />
          ))}
        </Styled.StatList>
      </Styled.Stats>
    </Styled.Root>
  );
};

interface StatsProps {
  stats: StatProps[];
  title: string;
  backgroundColor: string;
  theme?: string;
}

export interface StatProps {
  stat?: any;
  tag?: string;
  modifier?: string;
  className?: string;
  numStats?: number;
  bodyColor?: string;
  theme?: string;
}

export default Stats;
